import * as React from "react"

import AnimationWrapper from "./animationWrapper"

import Icons1 from '../../static/img/microsoft-365-icons-1.png'
import Icons2 from '../../static/img/microsoft-365-icons-2.png'
import Icons3 from '../../static/img/microsoft-365-icons-3.png'
import OneDrive from '../../static/img/microsoft-365-icons-onedrive.png'
import Feature from '../../static/img/microsoft-365-icons-feature.png'

const Microsoft365Table = ({ }) => {

  return (
    <div className="microsoft-365-table-wrapper">

      <AnimationWrapper>
        <table className="microsoft-365-table package-comparison-table">
          <tr>
            <th></th>
            <th>Microsoft 365 Business <b>Basic</b></th>
            <th>Microsoft 365 Business <b>Standard</b></th>
            <th>Microsoft 365 Business <b>Premium</b></th>
            <th><div className="th-ribbon">Obejmuje tylko applikacje</div><b>Aplikacje</b> Microsoft 365</th>
          </tr>
          <tr>
            <td><b>Dla kogo?</b></td>
            <td>Najlepsze rozwiązanie dla firm, które potrzebują prostych rozwiązań zdalnych z usługą Microsoft Teams, bezpiecznym magazynem w chmurze i usługą Office Online (nie obejmuje wersji klasycznych).</td>
            <td>Najlepsze rozwiązanie dla firm, które potrzebują narzędzi do pełnej pracy zdalnej i współpracy — obejmuje usługę Microsoft Teams, bezpieczny magazyn w chmurze, biznesową pocztę e-mail i aplikacje Premium pakietu Office na urządzeniach.</td>
            <td>Najlepsze rozwiązanie dla firm, które wymagają bezpiecznych rozwiązań do pracy zdalnej ze wszystkimi funkcjami zawartymi w wersji Business Standard oraz zaawansowanej ochrony przed zagrożeniami cybernetycznymi i zarządzania urządzeniami.</td>
            <td>Najlepsze rozwiązanie dla firm, które potrzebują aplikacji pakietu Office na różnych urządzeniach oraz magazynu plików w chmurze. Brak biznesowej poczty e-mail i usługi Microsoft Teams.</td>
          </tr>
          <tr>
            <td><b>Dostępne aplikacje pakietu Office</b></td>
            <td>Obejmuje internetowe i mobilne wersje aplikacji Word, Excel i PowerPoint.</td>
            <td><img src={Icons1} alt="Microsoft 365"/></td>
            <td><img src={Icons1} alt="Microsoft 365"/></td>
            <td><img src={Icons1} alt="Microsoft 365"/></td>
          </tr>
          <tr>
            <td><b>Dostępne usługi</b></td>
            <td><img src={Icons2} alt="Microsoft 365"/></td>
            <td><img src={Icons2} alt="Microsoft 365"/></td>
            <td><img src={Icons3} alt="Microsoft 365"/></td>
            <td><img src={OneDrive} className="onedrive-icon" alt="Microsoft 365"/></td>
          </tr>
        </table>
      </AnimationWrapper>
      <AnimationWrapper>
        <table className="microsoft-365-table feature-list-table">
          <tr>
            <th colspan="5" className="table-title">Praca zespołowa i komunikacja</th>
          </tr>
          <tr>
            <th></th>
            <th>Microsoft 365 Business <b>Basic</b></th>
            <th>Microsoft 365 Business <b>Standard</b></th>
            <th>Microsoft 365 Business <b>Premium</b></th>
            <th><b>Aplikacje</b> Microsoft 365</th>
          </tr>
          <tr>
            <td>Organizuj spotkania online i prowadź rozmowy wideo nawet z 300 osobami dzięki usłudze Microsoft Teams</td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td></td>
          </tr>
          <tr>
            <td>Czatuj z zespołem z komputera stacjonarnego lub w podróży, korzystając z usługi Microsoft Teams</td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td></td>
          </tr>
          <tr>
            <td>Połącz wszystkie czaty, spotkania, pliki i aplikacje zespołu, aby zapewnić łatwą komunikację i współpracę w jednym miejscu dzięki usłudze Microsoft Teams</td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td></td>
          </tr>
          <tr>
            <td>Twórz witryny zespołów i udostępniaj informacje, zawartość oraz pliki w całym intranecie przy użyciu programu SharePoint</td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td></td>
          </tr>
          <tr>
            <td>Dołączaj do spotkań w podróży, korzystając z numeru połączenia konferencyjnego w usłudze Konferencje głosowe</td>
            <td>Dostępne jako <b>dodatek</b></td>
            <td>Dostępne jako <b>dodatek</b></td>
            <td>Dostępne jako <b>dodatek</b></td>
            <td></td>
          </tr>
          <tr>
            <td>Organizuj seminaria internetowe, które obejmują strony rejestracji uczestników, potwierdzenia e-mail i raporty	</td>
            <td></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td></td>
          </tr>
        </table>
      </AnimationWrapper>
      <AnimationWrapper>
        <table className="microsoft-365-table feature-list-table">
          <tr>
            <th colspan="5" className="table-title">Wersje internetowe i mobilne aplikacji pakietu Office</th>
          </tr>
          <tr>
            <th></th>
            <th>Microsoft 365 Business <b>Basic</b></th>
            <th>Microsoft 365 Business <b>Standard</b></th>
            <th>Microsoft 365 Business <b>Premium</b></th>
            <th><b>Aplikacje</b> Microsoft 365</th>
          </tr>
          <tr>
            <td>Internetowe wersje aplikacji Word, Excel, PowerPoint i OneNote</td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/> Dodatkowo internetowa wersja aplikacji Outlook</td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/> Dodatkowo internetowa wersja aplikacji Outlook</td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/> Dodatkowo internetowa wersja aplikacji Outlook</td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
          </tr>
          <tr>
            <td>Zawsze aktualne wersje aplikacji Word, Excel, PowerPoint i OneNote na urządzeniach z systemem iOS i Android — na maksymalnie 5 urządzeniach przenośnych i 5 tabletach</td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/> Dodatkowo program Outlook</td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/> Dodatkowo program Outlook</td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/> Dodatkowo program Outlook</td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
          </tr>
          <tr>
            <td>Współtworzenie w czasie rzeczywistym pozwala wielu użytkownikom pracować jednocześnie nad tym samym dokumentem</td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
          </tr>
        </table>
      </AnimationWrapper>
      <AnimationWrapper>
        <table className="microsoft-365-table feature-list-table">
          <tr>
            <th colspan="5" className="table-title">Poczta e-mail i funkcje kalendarza</th>
          </tr>
          <tr>
            <th></th>
            <th>Microsoft 365 Business <b>Basic</b></th>
            <th>Microsoft 365 Business <b>Standard</b></th>
            <th>Microsoft 365 Business <b>Premium</b></th>
            <th><b>Aplikacje</b> Microsoft 365</th>
          </tr>
          <tr>
            <td>Hosting poczty e-mail ze skrzynką pocztową o rozmiarze 50 GB</td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td></td>
          </tr>
          <tr>
            <td>Korzystaj z własnej niestandardowej nazwy domeny (na przykład twoja_nazwa@twoja_firma.com)</td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td></td>
          </tr>
          <tr>
            <td>Poczta e-mail klasy biznesowej na urządzeniach przenośnych, tabletach, komputerach i w Internecie dzięki programowi Exchange</td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td></td>
          </tr>
          <tr>
            <td>Zarządzaj kalendarzem, udostępniaj wolne terminy spotkań, planuj spotkania i korzystaj z przypomnień</td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td></td>
          </tr>
          <tr>
            <td>Z łatwością planuj spotkania i odpowiadaj na zaproszenia dzięki kalendarzom udostępnionym</td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td></td>
          </tr>
          <tr>
            <td>Możesz między innymi konfigurować nowe konta e-mail dla użytkowników, przywracać usunięte konta i tworzyć skrypty niestandardowe</td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td></td>
          </tr>
        </table>
      </AnimationWrapper>
      <AnimationWrapper>
        <table className="microsoft-365-table feature-list-table">
          <tr>
            <th colspan="5" className="table-title">Przechowywanie i udostępnianie plików</th>
          </tr>
          <tr>
            <th></th>
            <th>Microsoft 365 Business <b>Basic</b></th>
            <th>Microsoft 365 Business <b>Standard</b></th>
            <th>Microsoft 365 Business <b>Premium</b></th>
            <th><b>Aplikacje</b> Microsoft 365</th>
          </tr>
          <tr>
            <td>1 TB magazynu w usłudze OneDrive do przechowywania i udostępniania plików</td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
          </tr>
          <tr>
            <td>Pracuj nad plikiem i zapisuj go bezpośrednio w usłudze OneDrive lub programie SharePoint — zmiany zostaną zaktualizowane na synchronizowanych urządzeniach</td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/> Tylko usługa OneDrive</td>
          </tr>
          <tr>
            <td>Udostępniaj pliki kontaktom zewnętrznym za pomocą linków dostępu lub linków gościa</td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
          </tr>
          <tr>
            <td>Bezpiecznie udostępniaj dokumenty i wysyłaj wiadomości e-mail, aby tylko osoby z odpowiednimi uprawnieniami miały dostęp do tych informacji</td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
          </tr>
          <tr>
            <td>Uzyskuj dostęp do plików i synchronizuj je na komputerze PC lub Mac oraz urządzeniach przenośnych</td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
          </tr>
        </table>
      </AnimationWrapper>
      <AnimationWrapper>
        <table className="microsoft-365-table feature-list-table">
          <tr>
            <th colspan="5" className="table-title">Klasyczne wersje aplikacji pakietu Office dla komputerów PC i Mac</th>
          </tr>
          <tr>
            <th></th>
            <th>Microsoft 365 Business <b>Basic</b></th>
            <th>Microsoft 365 Business <b>Standard</b></th>
            <th>Microsoft 365 Business <b>Premium</b></th>
            <th><b>Aplikacje</b> Microsoft 365</th>
          </tr>
          <tr>
            <td>Pełne, instalowane i zawsze aktualne wersje programów Outlook, Word, Excel, PowerPoint i OneNote dla systemu Windows lub dla komputerów Mac (oraz programów Access i Publisher tylko dla komputerów PC)</td>
            <td></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
          </tr>
          <tr>
            <td>Każdy użytkownik może zainstalować aplikacje pakietu Office na maksymalnie 5 komputerach PC lub Mac</td>
            <td></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
          </tr>
        </table>
      </AnimationWrapper>
      <AnimationWrapper>
        <table className="microsoft-365-table feature-list-table">
          <tr>
            <th colspan="5" className="table-title">Pomoc techniczna i wdrożenia</th>
          </tr>
          <tr>
            <th></th>
            <th>Microsoft 365 Business <b>Basic</b></th>
            <th>Microsoft 365 Business <b>Standard</b></th>
            <th>Microsoft 365 Business <b>Premium</b></th>
            <th><b>Aplikacje</b> Microsoft 365</th>
          </tr>
          <tr>
            <td>Całodobowa pomoc techniczna przez telefon i za pośrednictwem Internetu</td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
          </tr>
          <tr>
            <td>Ciesz się spokojem dzięki finansowej gwarancji dostępności na poziomie 99,9%</td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
          </tr>
          <tr>
            <td>Maksymalna liczba użytkowników</td>
            <td>300</td>
            <td>300</td>
            <td>300</td>
            <td>300</td>
          </tr>
          <tr>
            <td>Licencja do użytku komercyjnego</td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
          </tr>
        </table>
      </AnimationWrapper>
      <AnimationWrapper>
        <table className="microsoft-365-table feature-list-table">
          <tr>
            <th colspan="5" className="table-title">Zaawansowana ochrona przed zagrożeniami</th>
          </tr>
          <tr>
            <th></th>
            <th>Microsoft 365 Business <b>Basic</b></th>
            <th>Microsoft 365 Business <b>Standard</b></th>
            <th>Microsoft 365 Business <b>Premium</b></th>
            <th><b>Aplikacje</b> Microsoft 365</th>
          </tr>
          <tr>
            <td>Ochrona usługi Office 365 w usłudze Microsoft Defender pomaga chronić użytkowników przed wyrafinowanymi zagrożeniami ukrytymi w załącznikach i linkach przesyłanych pocztą e-mail oraz zapewnia zabezpieczenia przed atakami typu zero-day, oprogramowaniem wymuszającym okup i innymi atakami z wykorzystaniem złośliwego oprogramowania</td>
            <td></td>
            <td></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td></td>
          </tr>
          <tr>
            <td>Zdalnie czyść firmowe dane z zagubionych lub skradzionych urządzeń przy użyciu funkcji czyszczenia selektywnego dostępnej w ramach usługi Intune</td>
            <td></td>
            <td></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td></td>
          </tr>
          <tr>
            <td>Ograniczaj kopiowanie i zapisywanie firmowych danych w nieautoryzowanych aplikacjach i lokalizacjach dzięki funkcji ochrony aplikacji mobilnych pakietu Office</td>
            <td></td>
            <td></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td></td>
          </tr>
          <tr>
            <td>Kontroluj, kto ma dostęp do firmowych danych, stosując ograniczenia, na przykład „nie kopiować” i „nie przesyłać dalej”, za pomocą narzędzia Zarządzanie prawami do informacji</td>
            <td></td>
            <td></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td></td>
          </tr>
          <tr>
            <td>Stosuj zasady zapewniające ochronę przed zagrożeniami wcześnie wykrywającą ataki w systemie Windows 10 za pomocą funkcji Windows Defender Exploit Guard</td>
            <td></td>
            <td></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td></td>
          </tr>
          <tr>
            <td>Program Windows Defender umożliwia wymuszanie ochrony przed złośliwym oprogramowaniem w celu zabezpieczenia urządzeń z systemem Windows 10 przed wirusami, oprogramowaniem szpiegującym i innymi</td>
            <td></td>
            <td></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td></td>
          </tr>
          <tr>
            <td>Rozwiązanie Exchange Online — archiwum udostępnia nieograniczone archiwum w chmurze i pozwala stosować zasady długookresowego zachowywania danych</td>
            <td></td>
            <td></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td></td>
          </tr>
        </table>
      </AnimationWrapper>
      <AnimationWrapper>
        <table className="microsoft-365-table feature-list-table">
          <tr>
            <th colspan="5" className="table-title">Zarządzanie urządzeniami przenośnymi i komputerami</th>
          </tr>
          <tr>
            <th></th>
            <th>Microsoft 365 Business <b>Basic</b></th>
            <th>Microsoft 365 Business <b>Standard</b></th>
            <th>Microsoft 365 Business <b>Premium</b></th>
            <th><b>Aplikacje</b> Microsoft 365</th>
          </tr>
          <tr>
            <td>Łatwy w użyciu kreator konfiguracji umożliwia konfigurowanie funkcji i ustawień zabezpieczeń na komputerach z systemem Windows 10 i urządzeniach przenośnych z systemem iOS lub Android®	</td>
            <td></td>
            <td></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td></td>
          </tr>
          <tr>
            <td>Zarządzaj zasadami stosowanymi do komputerów z systemem Windows 10 za pomocą prostych mechanizmów kontroli	</td>
            <td></td>
            <td></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td></td>
          </tr>
          <tr>
            <td>Automatycznie wdrażaj aplikacje pakietu Office na komputerach z systemem Windows 10	</td>
            <td></td>
            <td></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td></td>
          </tr>
          <tr>
            <td>Skonfiguruj na komputerach PC automatyczne instalowanie aktualizacji pakietu Office i systemu Windows 10	</td>
            <td></td>
            <td></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td></td>
          </tr>
          <tr>
            <td>Stosuj zasady zabezpieczeń, aby chronić firmowe dane na wszystkich urządzeniach, w tym na urządzeniach z systemami iOS i Android® oraz komputerach z systemem Windows, dzięki usłudze Mobile Device Management w ramach usługi Intune	</td>
            <td></td>
            <td></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td></td>
          </tr>
        </table>
      </AnimationWrapper>
      <AnimationWrapper>
        <table className="microsoft-365-table feature-list-table">
          <tr>
            <th colspan="5" className="table-title">Narzędzia do budowania firmy i zarządzania nią</th>
          </tr>
          <tr>
            <th></th>
            <th>Microsoft 365 Business <b>Basic</b></th>
            <th>Microsoft 365 Business <b>Standard</b></th>
            <th>Microsoft 365 Business <b>Premium</b></th>
            <th><b>Aplikacje</b> Microsoft 365</th>
          </tr>
          <tr>
            <td>Zbieraj opinie od klientów i pracowników za pomocą usługi Microsoft Forms	</td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
          </tr>
          <tr>
            <td>Planowanie harmonogramów i codziennych zadań za pomocą aplikacji Microsoft Teams	</td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td></td>
          </tr>
          <tr>
            <td>Śledzenie informacji i prac najważniejszych dla Twojego zespołu za pomocą aplikacji Microsoft Lists	</td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td></td>
          </tr>
          <tr>
            <td>Aplikacja Microsoft Bookings ułatwia klientom rezerwowanie terminów spotkań i zarządzanie nimi</td>
            <td></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td><img src={Feature} className="feature-icon" alt="Microsoft 365"/></td>
            <td></td>
          </tr>
        </table>
      </AnimationWrapper>
    </div>
  )
}

export default Microsoft365Table